// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("jquery")
require("@nathanvda/cocoon")

import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css'
import Rails from "@rails/ujs"
// import useContext from 'react_ujs'
import './application.scss'

import 'select2/dist/css/select2.css'

Rails.start()
Turbolinks.start()
ActiveStorage.start()
// Support component names relative to this directory:
// var componentRequireContext = require.context("components", true);
/* eslint @typescript-eslint/no-var-requires: "off" */
// var ReactRailsUJS = require("react_ujs");
// useContext(componentRequireContext);
